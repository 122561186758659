<div class="page-content">
    <div class="container home">
        <div class="row mt-5">
            <div class="col-md-12 col-sm-12 " > 
                <div class="alert alert-light d-flex justify-content-between" role="alert">
                <h1>Bienvenido,  <span class="text-secondary ml-3" style="font-size: 25px;">{{Cod_usuario}}</span> </h1>   
                <img src="{{this.LogoEmpresa}}" style="height: 50px;">
            </div>         
            </div>       
        </div>
        <div class="row mt-3" >
            <div class="col-12">
                <div class="card curso-bajada shadow-sm">                          
                    <div class="card-body d-flex justify-content-between">
                            <h1 class="card-title mx-auto">¡Estos son tus números!</h1>      
                            
                    </div>  
                    <div class="card-body" *ngIf="this.Cursos!==undefined">
                        <div class="row">
                            <div class="col-md-4 mb-2">
                                <div class="card chart-card">
                                    <div class="card-body">

                                       <div class="single-funfact">
                                           <div class="icon">
                                           <h2>Total Usuarios</h2>
                                            </div>
                                            <h1 style="font-size: 100px;" ><ng9-odometer [number]="this.Numeros.TOTAL_ESTUDIANTE" [config]="{ }"></ng9-odometer></h1>
                                            <i class="fas fa-user-graduate fa-4x" style="color:#4caf50;"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-2">
                                <div class="card chart-card">
                                    <div class="card-body">
                                        <div class="single-funfact">
                                            <div class="icon">
                                            <h2>Total Cursos</h2>
                                             </div>
                                             <h1 style="font-size: 100px;" ><ng9-odometer [number]="this.Numeros.TOTAL_CURSOS" [config]="{ }"></ng9-odometer></h1>
                                             <i class="fas fa-book fa-4x" style="color:#4caf50;"></i>
                                            
                                         </div>
                                    </div>
                                </div>
                            </div>  
                            <div class="col-md-4 mb-2">
                                <div class="card chart-card">
                                    <div class="card-body d-flex justify-content-between ">
                                        <apx-chart
                                        [series]="chartOptions2.series"
                                        [chart]="chartOptions2.chart"
                                        [xaxis]="chartOptions2.xaxis"
                                        [title]="chartOptions2.title"
                                      ></apx-chart>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                    </div>    
                        
                
                </div> 
            </div>
        </div>
        <div class="row mt-3" >
            <div class="col-12">
                <div class="card curso-bajada shadow-sm">                          
                    <div class="card-body d-flex justify-content-between">
                            <h1 class="card-title mx-auto">Tus Cursos contratados</h1>      
                            
                    </div>  
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12" >
                                <div class="row">
                                    <div class="col-sm-12" >
                                        <ul class="cursos">
                                            <li *ngFor="let curso of this.Cursos">
                                                <div class="card card-curso shadow-sm" >                        
                                                    <img src="{{curso.IMG_CURSO}}" style="border-top-left-radius: 15px;border-top-right-radius: 15px;">                       
                                                        <div class="card-body" style="font-weight: 500;
                                                        font-size: 14px;
                                                        color: #9ba0a7;
                                                        line-height: 23px;">
                                                            <h5 class="card-title">{{curso.NOMBRE_CURSO}}</h5>
                                                            <p class="card-text "> <i class="mdi mdi-account" aria-hidden="true"></i> {{curso.TOTAL_ESTUDIANTES}} Usuarios</p>     
                                                            <p class="card-text"> <i class="mdi mdi-school"></i> {{curso.TOTAL_CERTIFICADOS}} Usuarios finalizados </p>                                        
                                                                                                                  
                                                        </div>
                                                        
                                                        <div class="card-footer rounded d-flex justify-content-between" >                                        
                                                            <button type="button"  class="btn btn-success btn-curso rounded-pill" (click)="openDialog(curso.ID_CURSO)">ver más</button>
                                                        </div>                                                  
                                                </div>  
                                            </li>                                           
                                                                                
                                        </ul>    
                            </div>    
                                </div>  
                            </div>              
                        </div>
                    </div>    
                        
                
                </div> 
            </div>
        </div>
</div>
</div>