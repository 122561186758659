import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.component.html',
  styleUrls: ['./certificado.component.scss']
})
export class CertificadoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  Print() {
    this.downloadPDF()
  }
  downloadPDF() {
    // Extraemos el
    window.scroll(0, 0);

    const DATA = document.getElementById('page_1');

    const doc = new jsPDF('l');

    html2canvas(DATA).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 0;
      const bufferY = 0;
      const imgProps = (doc as any).getImageProperties(img);

      const pdfWidth = doc.internal.pageSize.getWidth() - 2;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}_certificado.pdf`);
    });
  }
}
