import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from "./gobal/material.module";
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng9OdometerModule } from 'ng9-odometer'; // <-- import the module
import { PagesComponent } from './pages/pages.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { CursoComponent } from './pages/curso/curso.component';
import { ModuloComponent } from './pages/modulo/modulo.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NavbarComponent } from './pages/compartidas/navbar/navbar.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ContadorComponent } from './pages/compartidas/contador/contador.component';
import { CursoDetalleComponent } from './pages/admin/curso-detalle/curso-detalle.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { AuthInterceptor } from './_helper/auth.interceptor';
import { AuthGuard } from './_helper/auth.guard';
import { AuthService } from './_helper/auth.service';
import { InformesComponent } from './pages/admin/informes/informes.component';
import { ChatComponent } from './pages/compartidas/chat/chat.component';
import { IntroComponent } from './pages/compartidas/intro/intro.component';
import { EvaluacionComponent } from './pages/evaluacion/evaluacion.component';
import { LoaderComponent } from './pages/compartidas/loader/loader.component';
import { LoaderInterceptor } from './servicios/service/loader-interceptor.service';
import { LoaderService } from './servicios/service/loader.service';

import { CertificadoComponent } from './pages/compartidas/certificado/certificado/certificado.component';



@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    InicioComponent,
    CursoComponent,
    ModuloComponent,
    LoginComponent,
    NavbarComponent,
    AdminComponent,
    ContadorComponent,
    CursoDetalleComponent,
    PerfilComponent,
    InformesComponent,
    ChatComponent,
    IntroComponent,
    EvaluacionComponent,
    LoaderComponent,
    CertificadoComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgApexchartsModule,
    MaterialModule,
    Ng9OdometerModule.forRoot(),

  ],
  providers: [
    AuthGuard,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
