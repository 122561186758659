import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { URL_SERVICIOS } from "src/app/Config/config";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class EvaluacionService {
  private readonly API_URL = URL_SERVICIOS + "api/Evaluacion";
  constructor(private httpClient: HttpClient, private router: Router) { }

  getEvaluacionPreguntas(hash: string) {
    let url = this.API_URL + "/EvaluacionPreguntas/" + hash;
    return this.httpClient.get(url).pipe(
      map((resp: any) => {

        return resp;
      }),
      catchError(err => {
        this.router.navigate(["/login"]);
        throw 'error in source. Details: ' + err;
      }),
    );
  }
  getFinEvaluacion(id_evaluacion: number) {
    let url = this.API_URL + "/EvaluacionTerminada/" + id_evaluacion;
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError(err => {
        //this.router.navigate(["/login"]);
        throw 'error in source. Details: ' + err;
      }),
    );
  }
}
