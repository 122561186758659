import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { URL_SERVICIOS } from "src/app/Config/config";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private readonly API_URL = URL_SERVICIOS + "api/Admin";
  constructor(private httpClient: HttpClient, private router: Router) { }

  getDatosAdmin() {
    let url = this.API_URL + "/DatosAdmin";
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError(err => {
        this.router.navigate(["/login"]);
        throw 'error in source. Details: ' + err;
      }),
    );
  }
  getDatosAdminGolbal() {
    let url = this.API_URL + "/DatosAdminGolbal";
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError(err => {
        this.router.navigate(["/login"]);
        throw 'error in source. Details: ' + err;
      }),
    );
  }
  getDatosCursoAdmin(id_curso) {
    let url = this.API_URL + "/DatosCursoAdmin/" + id_curso;
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError(err => {
        this.router.navigate(["/login"]);
        throw 'error in source. Details: ' + err;
      }),
    );
  }

}
