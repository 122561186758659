<div class="sidenav shadow-sm " *ngIf="this.ID_PERFIL==='1'">
<ul>
    <li routerLinkActive="activo" routerLink="/App/Inicio" >
        <img class="icon-sidenav"   src="../../assets/img/icon/01_Home.png" >
    </li>
    <li routerLinkActive="activo" routerLink="/App/Perfil">
        <img class="icon-sidenav" routerLinkActive="activo" src="../../assets/img/icon/02_Perfil.png">
    </li>
    <li routerLinkActive="activo" routerLink="/App/Curso">
        <img class="icon-sidenav" routerLinkActive="activo"  src="../../assets/img/icon/03_Clases.png">
    </li>   
</ul>
</div>
<div class="sidenav shadow-sm " *ngIf="this.ID_PERFIL==='2'">
    <ul>
        <li routerLinkActive="activo" routerLink="/App/Admin" >
            <i class="fab fa-adn fa-3x text-light"></i>
            <i class=" " aria-hidden="true"></i>
        </li>
        <li routerLinkActive="activo" routerLink="/App/Informes" >
            <i class="fas fa-chart-pie  fa-3x text-light"></i>
            <i class=" " aria-hidden="true"></i>
        </li>
    </ul>
    </div>