import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CursoService } from 'src/app/servicios/curso/curso.service';
import { InicioService } from "src/app/servicios/inicio/inicio.service"
import {
  ChartComponent,
  ApexNonAxisChartSeries,
  ApexChart,
  ApexPlotOptions,
  ApexFill,
  ApexStroke,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexXAxis
} from "ng-apexcharts";
import { MatDialog } from '@angular/material/dialog';
import { IntroComponent } from '../compartidas/intro/intro.component';
import * as io from 'socket.io-client';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2'
import { saveAs } from 'file-saver';
import { CertificadoComponent } from '../compartidas/certificado/certificado/certificado.component';
//import { saveAs } from 'file-saver';

@Component({
  selector: 'app-curso',
  templateUrl: './curso.component.html',
  styleUrls: ['./curso.component.scss']
})
export class CursoComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public cargando: boolean = true;
  public chartOptions1: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions2>;
  public datosCurso: DatosCurso;
  public Casos: CASOS;
  public id;
  public progressInfos = [];
  public selectedFiles: FileList;
  public datosUsuarios: DatosUsuario = {
    ID_ESTUDIANTE: 0,
    NOMBRE_ESTUDIANTE: "",
    NOMBRE_EMPRESA: "",
    LOGO_EMPRESA: "",
    CURSOS: []
  }
  public eva_modulo_fin: number = 0
  constructor(
    public CursoService: CursoService,
    public InicioService: InicioService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {



  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    if (this.id !== null) {
      let _curso = new URLSearchParams(atob(this.id))
      let curso = _curso.get('CursoId')
      this.CursoService.getDatosCurso(curso).subscribe(data => {
        this.datosCurso = data
        //console.log(this.datosCurso)

        if (this.datosCurso.RESULTADO >= 0.75) {
          Swal.fire({
            icon: 'success',
            title: 'Bien!',
            text: 'Haz completado tu curso',
            footer: 'Ya puedes descargar tu Certificado'
          })
        }


        let total_modulos = this.datosCurso.MODULOS.length
        this.eva_modulo_fin = this.datosCurso.MODULOS[total_modulos - 1].EVALUACION
        let minutos: number[] = []
        let dias: string[] = []
        this.datosCurso.MINUTOS_SEMANA.forEach(x => {
          minutos.push(x.MINUTOS)
          dias.push(x.DIA)
        })
        this.chartOptions1 = {
          series: [this.datosCurso.AVANCE],
          chart: {
            height: 250,
            type: 'radialBar',
            toolbar: {
              show: true
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: '70%',
                background: '#fff',
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: 'front',
                dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24
                }
              },
              track: {
                background: '#fff',
                strokeWidth: '67%',
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.35
                }
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: true,
                  color: '#888',
                  fontSize: '17px'
                },
                value: {
                  color: '#111',
                  fontSize: '20px',
                  show: true,
                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: ['#ABE5A1'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: 'round'
          },
          labels: ['Avance del curso'],
        };
        this.chartOptions2 = {
          series: [
            {
              name: "Minutos",
              data: minutos
            }
          ],
          chart: {
            height: 250,
            type: "bar"
          },
          title: {
            text: "Avance Semanal Min"
          },
          xaxis: {
            categories: dias
          }
        };
        this.CursoService.getCasosPracticos(curso).subscribe(data => {
          if (data !== 0) {
            this.Casos = data
            
          }
          this.cargando = false
        });
      })



    }
    else {
      this.InicioService.getDatosUsuarios().subscribe(data => {
        this.datosUsuarios = data
        this.cargando = false
      })
    }
  }

  Ayuda() {

    Swal.mixin({
      confirmButtonText: 'Siguiente &rarr;',
      showCancelButton: true,
      progressSteps: ['1', '2', '3']
    }).queue([
      {
        title: '¿Cómo funciona el curso?',
        text: 'Debes iniciar con el primer módulo y ver todas las clases',
        imageUrl: '../../../assets/img/help/img1.png',
        imageAlt: 'Custom image',
      },
      {
        text: 'Cuando veas todas las clases del módulo, podrás realizar la evaluación ',
        imageUrl: '../../../assets/img/help/img2.png',
        imageAlt: 'Custom image',
      },
      {
        text: 'Al terminar la evaluación con un mínimo de 75% de aciertos, se activará el próximo módulo',
        imageUrl: '../../../assets/img/help/img3.png',
        imageAlt: 'Custom image',
      }
    ]).then((result) => {

    })

  }

  VerIntro() {
    const dialogRef = this.dialog.open(IntroComponent);
  }
  IrModulo(id_modulo) {
    let _curso = new URLSearchParams(atob(this.id))
    let curso = _curso.get('CursoId')
    let curso_ = btoa("CursoId=" + curso)
    let Modulo_ = btoa("ModuloId=" + id_modulo)
    let hash = btoa(curso_ + ";" + Modulo_)
    this.router.navigate(['App/Modulo/' + hash]);
  }
  verCurso(id_curso) {
    let _curso = new URLSearchParams(atob(this.id))
    let curso = _curso.get('CursoId')
    let curso_ = btoa("CursoId=" + id_curso)
    this.router.navigate(['App/Curso/' + curso_]);
  }
  DescargaCaso(url) {
    window.open(url);
  }
  selectFiles(event) {
    this.selectedFiles = event.target.files;
  }
  uploadFiles() {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i]);
    }
  }
  upload(idx, file) {

  }
  IrEvaluacion(id_modulo) {
    const TOKEN_KEY = "auth-token";
    const token = sessionStorage.getItem(TOKEN_KEY);
    let _curso = new URLSearchParams(atob(this.id))
    let curso = _curso.get('CursoId')

    let curso_ = btoa("CursoId=" + curso)
    let Modulo_ = btoa("ModuloId=" + id_modulo)
    let Tipo_ = btoa("Tipo=2")
    let token_ = btoa("Token=")
    //let parametros = btoa("parametros=" + this.PARAMETROS)
    let hash = btoa(curso_ + ";" + Modulo_ + ";" + Tipo_ + ";" + token_)
    this.router.navigate(['App/Evaluacion/' + hash]);
  }
  IrEvaluacionFinal() {
    const TOKEN_KEY = "auth-token";
    const token = sessionStorage.getItem(TOKEN_KEY);
    let _curso = new URLSearchParams(atob(this.id))
    let curso = _curso.get('CursoId')
    let curso_ = btoa("CursoId=" + curso)
    let Modulo_ = btoa("ModuloId=" + 0)
    let Tipo_ = btoa("Tipo=1")
    let token_ = btoa("Token=" + token)
    //let parametros = btoa("parametros=" + this.PARAMETROS)
    let hash = btoa(curso_ + ";" + Modulo_ + ";" + Tipo_ + ";" + token_)
    this.router.navigate(['App/Evaluacion/' + hash]);
  }
  Certificado() {

    this.cargando = true
    const TOKEN_KEY = "auth-token";
    const token = sessionStorage.getItem(TOKEN_KEY);
    let token_ = btoa("Token=" + token)
    let curso_ = btoa("CursoId=" + this.datosCurso.ID_CURSO)
    let hash = btoa(curso_)
    this.CursoService.DescargaCertificado(hash).subscribe(data => {
      saveAs(data, hash + ".pdf");
      this.cargando = false
    });

  }


}



export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  plotOptions: ApexPlotOptions;
  labels: string[];
  fill: ApexFill;
  stroke: ApexStroke
};
export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};
export interface DatosCurso {
  ID_CURSO: number;
  NOMBRE_CURSO: string;
  CATEGORIA: string;
  DESCRIPCION_CURSO: string;
  IMG_CURSO: string;
  ICON_CURSO: string;
  COLOR: string;
  TOTAL_MODULOS: number;
  TOTAL_HORAS: number;
  VIDEO: string;
  AVANCE: number;
  MODULOS: Modulos[];
  MINUTOS_SEMANA: MINUTOS_SEMANA[];
  HORAS_CURSO: string;
  RESULTADO: number;
  TIPO:string;
}
export interface Modulos {
  ID_MODULO: number;
  NOMBRE_MODULO: string;
  TOTAL_CLASES: string;
  TOTAL_HORA: string;
  AVANCE: number;
  ESTADO: number;
  EVALUACION: number;
}
export interface CASOS {
  ID_EJERCICIO: number;
  NOMBRE: string;
  DESCRIPCION: string;
  URL_DESCARGA: string;
}
export interface DatosUsuario {
  ID_ESTUDIANTE: number;
  NOMBRE_ESTUDIANTE: string;
  NOMBRE_EMPRESA: string;
  LOGO_EMPRESA: string;
  CURSOS: CURSOS[];
}
export interface CURSOS {
  ID_CURSO: number;
  NOMBRE_CURSO: string;
  CATEGORIA: string;
  DESCRIPCION_CURSO: string;
  IMG_CURSO: string;
  COLOR: string;
  TOTAL_MODULOS: number;
  TOTAL_HORAS: number;
  AVANCE: number;
  EVALUACION: number;
}
export interface MINUTOS_SEMANA {
  DIA: string;
  MINUTOS: number;
}