import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { URL_SERVICIOS } from '../Config/config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(public http: HttpClient) { }
  Auth() {
    let url = URL_SERVICIOS + "api/login/echouser";
    return this.http.get(url).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }
}
