<div class="load" *ngIf="loader===true" >
    <div class="loader"></div>
</div>
<div class="page-content">
    <div class="container home">
        <div class="row mt-5">
            <div class="col-md-12 col-sm-12 " >
                <h1>Informe  <span class="text-secondary ml-3">Global</span> </h1>            
            </div>       
        </div>
        <div class="row mt-3">
            <div class="col-md-12 col-sm-12 mb-2">
                <div class="card curso shadow-lg " style="background-color: #ffffff">
                
                    <div class="card-body ml-5" >
                        <h1 class="card-title">Estado global de tus cursos</h1>
                        <img class="curso-icon" src="{{LogoEmpresa}}" >      
                    </div>
                    <div class="card-body ml-5" >
                        <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="card chart-card">
                                <div class="card-body">
                                    <div class="single-funfact">
                                        <div class="icon">
                                        <h2>Total Cursos</h2>
                                         </div>
                                         <h1 style="font-size: 100px;" ><ng9-odometer [number]="this.Numeros.TOTAL_CURSOS" [config]="{ }"></ng9-odometer></h1>
                                         <i class="fas fa-book fa-4x" style="color:#4caf50;"></i>
                                        
                                     </div>
                                </div>
                            </div>
                        </div> 
                        <div class="col-md-6 mb-2">
                            <div class="card chart-card">
                                <div class="card-body">
                                    <div class="single-funfact">
                                        <div class="icon">
                                        <h2>Total Alumnos</h2>
                                         </div>
                                         <h1 style="font-size: 100px;" ><ng9-odometer [number]="this.Numeros.TOTAL_ESTUDIANTES" [config]="{ }"></ng9-odometer></h1>
                                         <i class="fas fa-user-graduate fa-4x" style="color:#4caf50;"></i>
                                        
                                     </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    </div>
                </div>
            </div>         
        </div>     
        <div class="row mt-3 mb-3" >
            <div class="col-12">
                <div class="card curso-bajada shadow-sm">                          
                    <div class="card-body d-flex justify-content-between">
                            
                            <button type="button" class="btn btn-success " style="   position: absolute;                           
                            right: 40px;
                            top: 10px;
                            text-align: center;" (click)="downloadPDF()">Descargar a PDF</button>
                            
                    </div>  
                    <div class="card-body" id="pdfDownload1">             
                        <h1 class="card-title mx-auto mb-3" style="text-align: center;">Metrica Global</h1>                 
                        <div class="row mb-3" >
                            <div class="col-md-8 offset-2 mb-2 col-12">
                                <div class="card chart-card">
                                    <div class="card-body">
                                       <apx-chart
                                        [series]="chartOptions0.series"
                                        [chart]="chartOptions0.chart"
                                        [labels]="chartOptions0.labels"                                 
                                        [title]="chartOptions0.title"
                                      ></apx-chart>
                                    </div>
                                </div>
                            </div>
                         <!--   <div class="col-md-6 mb-2 col-12">
                                <div class="card chart-card">
                                    <div class="card-body">
                                        <apx-chart
                                        [series]="chartOptions1.series"
                                        [chart]="chartOptions1.chart"
                                        [labels]="chartOptions1.labels"                                       
                                        [title]="chartOptions1.title"
                                      ></apx-chart>
                                    </div>
                                </div>
                            </div>-->                     
                        </div>
                        <div class="row" >
                             <div class="col-md-8 offset-2 mb-2 col-12">
                                <div class="card chart-card">
                                    <div class="card-body mx-auto">
                                       <apx-chart
                                        [series]="chartOptions2.series"
                                        [chart]="chartOptions2.chart"
                                        [xaxis]="chartOptions2.xaxis"
                                        [title]="chartOptions2.title"
                                      ></apx-chart>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                    </div>    
                        
                
                </div> 
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
        <!-- Recent Order Table -->
        <div class="card curso-bajada shadow-sm">             
            <div class="card-body d-flex justify-content-between">
                <h1 class="card-title mx-auto">Listado de Alumnos</h1>      
                
        </div>  
        <div class="card-body pt-0 pb-5 f">
            <button type="button" class="btn btn-success " style="   position: absolute;                           
            right: 40px;
            top: 10px;
            text-align: center;" (click)="exportexcel()">Descargar a Excel</button>
        <table class="table card-table table-striped" style="width:100%" id="excel-table">
        <thead>
        <tr>
            <th>Nombre</th>
            <th class="d-none d-lg-table-cell">Curso </th>
            <th class="d-none d-lg-table-cell">Fecha última conexión </th>            
            <th class="d-none d-lg-table-cell">Avance del curso</th>
            <th>Estado</th>
            <th>Nota Final</th>
            <th>Certificado</th>            
            <th>DJ</th>
            <th>CI</th>
        </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of this.TABLA_ESTUDIANTES">
                <td>{{item.NOMBRE_ESTUDIANTE}}</td>
                <td>{{item.CURSO}}</td>
                <td>
                    
                <a class="text-dark"> {{item.FECHA_CONEXION}}</a>
                </td>
                <td class="d-none d-lg-table-cell">
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" [style.width]="item.AVANCE/100 | percent" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">{{item.AVANCE}}%</div>
                    </div>
                </td>
                <td>
                    <span class="badge" [ngClass]="{'badge-warning':item.ESTADO=='Iniciado' , 'badge-danger':item.ESTADO=='No Iniciado' , 'badge-success':item.ESTADO=='Terminado' }" >{{item.ESTADO}}</span>
                </td>
                <td>
                    <span class="badge badge-info">{{item.NOTAL_FINAL | percent}}</span>
                </td>
                <td>
                    <span class="badge badge-info">{{item.CERTIFICADO==1 ? 'Obtenido' : 'No Obtenido'}}</span>
                </td>
                <td>
                    <div class="checkbox checkbox-success">
                        <input id="checkbox" type="checkbox" checked *ngIf="item.DJ==1">
                        <input id="checkbox" type="checkbox" *ngIf="item.DJ!==1">
                      </div>
                </td>
                <td>
                    <div class="checkbox checkbox-success">
                        <input id="checkbox" type="checkbox" checked *ngIf="item.CI==1">
                        <input id="checkbox" type="checkbox" *ngIf="item.CI!==1">
                      </div>
                </td>
            </tr>   
      
        </tbody>
        </table>
        </div>
        </div>
        </div>
        </div>
    </div>
  


</div>





