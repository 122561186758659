<div class="page-content">
    <div class="container module">
        <div class="row">
            <div class="col-md-9 col-sm-12 list-clases" style="padding: inherit; height: 100%;" *ngIf="this.Preguntas!==undefined">
                               <div class="card clase-bajada" style="padding: inherit;">     
                    <div class="card-header titulo">
                        <h2 class="btn btn-outline-light btn-lg ml-5 mt-1" style="text-align: center;" >Evaluación</h2>                     
                    </div>
                    <div class="card-body">
                        <div class="video-container" id="vc">                                                
                         <iframe id="Iframe"  [src]="IFRAME" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>
                        

                        </div>  
                    </div>     
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                
                            </div>
                            <div class="col-6"></div>
                        </div>
                    </div>                           
                </div> 
                  
            </div>       
            <div class="col-md-3 col-sm-12 list-clases ">    
                <div class="card list-clases-scroll list-clases ">
                    <div class="card-header titulo ">
                        <h2 class="btn btn-outline-light btn-lg  ml-5 mt-1 " style="text-align: center;" >Preguntas</h2>                     
                    </div>
                    <div class="card-body">
                        <div class="row mx-auto ms-3" >
                           <div *ngFor="let pregunta of Preguntas ; index as i" class="col-3 card m-2 clases text-center" ngClass={{pregunta.ESTADO}} style="height: 60px; width: 60px;" (click)="click(pregunta.URL_IFRAME  , pregunta.ID_PREGUNTA)"><h1 class="text-center mt-2 text-light ">{{i + 1}}</h1></div>               
                        </div>               
                    </div>
                </div>             
            </div> 
    </div>
    <div class="row">
      
    </div>
    </div>
</div>
<div id='whatsapp-chat' class="{{chatwsp}}">
	<div class='header-chat'>
	<div class='head-home'><h3>Ditraining</h3>
		<div class='info-avatar'><img src='../../../assets/img/icon/supportmale.png'/></div>
		
	</div>
 
	<div class='get-new'>
		<div id='get-label'>Soporte</div>
		<div id='get-nama'>Servicio al cliente</div>		
	</div>
</div>
 
<div class='start-chat'>
<div class='first-msg'><span>¡Hola! ¿Qué puedo hacer por ti?</span></div>
<div class='blanter-msg'><textarea id='chat-input'  placeholder='Escribe un mensaje' maxlength='120' row='1'></textarea>
<a (click)="enviar_mensaje()" id='send-it'>Enviar</a></div></div>
<div id='get-number'></div><a class='close-chat' (click)="cerrar_chat();">×</a>
</div>
 
 
<a class='blantershow-chat' (click)="mostrar_chat()" title='Show Chat'><svg width="20" viewBox="0 0 24 24"><defs/><path fill="#eceff1" d="M20.5 3.4A12.1 12.1 0 0012 0 12 12 0 001.7 17.8L0 24l6.3-1.7c2.8 1.5 5 1.4 5.8 1.5a12 12 0 008.4-20.3z"/><path fill="#4caf50" d="M12 21.8c-3.1 0-5.2-1.6-5.4-1.6l-3.7 1 1-3.7-.3-.4A9.9 9.9 0 012.1 12a10 10 0 0117-7 9.9 9.9 0 01-7 16.9z"/><path fill="#fafafa" d="M17.5 14.3c-.3 0-1.8-.8-2-.9-.7-.2-.5 0-1.7 1.3-.1.2-.3.2-.6.1s-1.3-.5-2.4-1.5a9 9 0 01-1.7-2c-.3-.6.4-.6 1-1.7l-.1-.5-1-2.2c-.2-.6-.4-.5-.6-.5-.6 0-1 0-1.4.3-1.6 1.8-1.2 3.6.2 5.6 2.7 3.5 4.2 4.2 6.8 5 .7.3 1.4.3 1.9.2.6 0 1.7-.7 2-1.4.3-.7.3-1.3.2-1.4-.1-.2-.3-.3-.6-.4z"/></svg> 
Reportar un problema</a>