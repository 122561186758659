<div class="card" >
    <div class="card-header">
        <h1>Chat del Curso</h1>
    </div>
    <div class="card-body list-chat-scroll">
        <div class="chat" style="overflow: hidden; outline: none;" tabindex="5001">
            <div class="col-inside-lg decor-default">
            <div class="chat-body" id="chatBody">         
                
                
                <div class="answer" ngClass="{{item.class}}" *ngFor="let item of this.Chat">
                <div class="avatar">
                    <img src="{{item.img}}" alt="User name">
                    
                </div>
                <div class="name">{{item.name}}</div>
                <div class="text">
                    {{item.message}}
                </div>
                <div class="time">{{item.time}}</div>
                </div>
                
    
            
            </div>
            </div>
        </div>    
    </div>
    <div class="card-footer">
        <div class="answer-add">
            <textarea cols="10" placeholder="Escribe un mensaje" [(ngModel)]="message"></textarea>
            <mat-icon  class="answer-btn answer-btn-1" (click)="SendMessage()">send</mat-icon>                                          
        </div>
    </div>
</div>