<div class="content" style="height: 100%;">
  <img src="../../assets/img/icon/g_2.png" class="img-bg-left">
  <img src="../../assets/img/icon/g_1.png" class="img-bg-right">
    <header class="header shadow-sm ">      
        <nav class="navbar navbar-expand-sm justify-content-between">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="navbar-brand ml-5">
             <img class="imgLogo" src="../../assets/img/Logo_usofondooscuro.png">
            </a>
         
        </nav>
</header>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <img src="../../assets/img/icon/personaje inicio.png" alt="Image" class="img-fluid flotante">
        </div>
        <div class="col-md-6 contents">
          <div class="row justify-content-center">
             
            <div class="col-md-8 col-12">               
                <div class="card login">
              <div class="mb-4 mt-3 mx-auto">
            <img src="../../assets/img/Logo_Original.png" class="imgLogo img-center">
              <h1 class="mb-4 mt-3">Iniciar Sesión </h1>
            </div>
            <div *ngIf="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            <form name="ngForm" ngNativeValidate #form="ngForm">
              <div class="form-group first mb-3"  [ngClass]="{'field--not-empty': isFocused || Username.value.length>0}">
                <label for="user">Usuario</label>
                <input type="text" class="form-control" id="Username" name="Username" #Username [(ngModel)]="Login.Username"  required>

              </div>
              <div class="form-group last mb-4" [ngClass]="{'field--not-empty': isFocused1 || Password.value.length>0}">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="Password" name="Password" #Password [(ngModel)]="Login.Password" required >                
              </div>             
              

              <button class="btn btn-block btn-primary" (click)="login()">Entrar</button>      
            </form>
                </div>
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  </div>