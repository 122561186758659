import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuloService } from 'src/app/servicios/modulo/modulo.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Location } from '@angular/common'
import { DOCUMENT } from '@angular/common';
import * as io from 'socket.io-client';
import Swal from 'sweetalert2'
const TOKEN_KEY = "auth-token";
const SOCKET_ENDPOINT = 'https://chatditraining-paqqz.ondigitalocean.app/';

@Component({
  selector: 'app-modulo',
  templateUrl: './modulo.component.html',
  styleUrls: ['./modulo.component.scss']
})
export class ModuloComponent implements OnInit {
  public datosModulos: DatosModulos;
  public IFRAME: SafeResourceUrl;
  public Titulo: string = "";
  public PARAMETROS: string = "";
  public chatwsp: string = "hide";
  public msg: string = "";
  public CursoId: string = ""
  socket: any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public ModuloService: ModuloService,
    private router: Router,
    private route: ActivatedRoute,
    public dom: DomSanitizer,
    private location: Location

  ) {
    this.chatwsp = "hide";
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.socket = io(SOCKET_ENDPOINT);
  }

  ngOnInit(): void {
    this.document.body.classList.add('claro');
    const id = this.route.snapshot.paramMap.get("id");
    var data = atob(id).split(";")
    var d1 = new URLSearchParams(atob(data[0]))
    var d2 = new URLSearchParams(atob(data[1]))
    this.CursoId = d1.get('CursoId')
    var ModuloId = d2.get('ModuloId')
    const token = sessionStorage.getItem(TOKEN_KEY);
    this.ModuloService.getDatosModulo(ModuloId).subscribe(data => {

      this.datosModulos = data
    
      this.PARAMETROS = this.datosModulos.CLASES[0].PARAMETROS
      let vs: Clase = this.datosModulos.CLASES.find(x => x.VISTO === 1 && x.TERMINADO === 0);


      if (vs !== undefined) {
        let curso_b = btoa("ClaseId=" + vs.ID_CLASE)
        let token_ = btoa("Token=" + token)
        //let parametros = btoa("parametros=" + this.PARAMETROS)
        let hash = btoa(curso_b + ";" + token_)
        this.IFRAME = this.dom.bypassSecurityTrustResourceUrl(vs.URL_IFRAME + "?hash=" + hash);
        this.Titulo = vs.NOMBRE_CLASE
      }
      else {
        let vs_: Clase = this.datosModulos.CLASES.find(x => x.VISTO === 0 && x.TERMINADO === 0);
        if (vs_ !== undefined) {
          let curso_b = btoa("ClaseId=" + vs_.ID_CLASE)
          let token_ = btoa("Token=" + token)
          //let parametros = btoa("parametros=" + this.PARAMETROS)
          let hash = btoa(curso_b + ";" + token_)
          this.IFRAME = this.dom.bypassSecurityTrustResourceUrl(vs_.URL_IFRAME + "?hash=" + hash);
          this.Titulo = vs_.NOMBRE_CLASE
        }
        else {
          let curso_b = btoa("ClaseId=" + this.datosModulos.CLASES[0].ID_CLASE)
          let token_ = btoa("Token=" + token)
          //let parametros = btoa("parametros=" + this.PARAMETROS)
          let hash = btoa(curso_b + ";" + token_)
          this.IFRAME = this.dom.bypassSecurityTrustResourceUrl(this.datosModulos.CLASES[0].URL_IFRAME + "?hash=" + hash);
          this.Titulo = this.datosModulos.CLASES[0].NOMBRE_CLASE
        }
      }

    })
    this.setupSocketConnection(token);
  }
  setupSocketConnection(tok: string) {
    let pre = 0
    this.socket.emit('changehanel', tok);
    this.socket.on('fin-broadcast', (data: any) => {

      if (this.datosModulos.CLASES.find(x => x.ID_CLASE == data.ClaseId)) {

        let total_clases = this.datosModulos.CLASES.length
        let cla = this.datosModulos.CLASES.find(x => x.ID_CLASE == data.ClaseId)
        let i = this.datosModulos.CLASES.indexOf(cla)

        if (total_clases == i + 1) {
          Swal.fire({
            icon: 'success',
            title: 'Bien!!',
            text: 'Terminaste el modulo!!!'
          })

          Swal.fire({
            icon: 'success',
            title: 'Bien!!',
            text: 'Terminaste el modulo!!!',
            html: 'En <b></b> Segundos.',
            timer: 3000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              timerInterval = setInterval(() => {
                const content = Swal.getContent()
                if (content) {
                  const b = content.querySelector('b')
                  if (b) {
                    b.textContent = parseInt((Swal.getTimerLeft() / 1000).toString()).toString()
                  }
                }
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            /* Read more about handling dismissals below */
            this.Volver();
          })

        }

        this.datosModulos.CLASES[i].TERMINADO = 1
        this.datosModulos.CLASES[i + 1].VISTO = 1



        let timerInterval
        Swal.fire({
          title: 'Siguente Clase ',
          html: 'En <b></b> Segundos.',
          timer: 4000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            timerInterval = setInterval(() => {
              const content = Swal.getContent()
              if (content) {
                const b = content.querySelector('b')
                if (b) {
                  b.textContent = parseInt((Swal.getTimerLeft() / 1000).toString()).toString()
                }
              }
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            this.iframe(this.datosModulos.CLASES[i + 1].URL_IFRAME, this.datosModulos.CLASES[i + 1].NOMBRE_CLASE)
          }
        })
      }


    });


  }

  enviar_mensaje() {

    if ("" != this.msg) {

      let d = "https://api.whatsapp.com/send?phone=56953665461&text=" + this.msg
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        d = "whatsapp://send";
      }
      window.open(d, "_blank");

    }
  }
  mostrar_chat() {
    this.chatwsp = "show";
  }
  cerrar_chat() {
    this.chatwsp = "hide";
  }
  iframe(URL, titulo) {
    const token = sessionStorage.getItem(TOKEN_KEY);

    let vs: Clase = this.datosModulos.CLASES.find(x => x.NOMBRE_CLASE === titulo);
    let indexof = this.datosModulos.CLASES.indexOf(vs);
    let ant: Clase = this.datosModulos.CLASES.find(x => x.NOMBRE_CLASE === this.Titulo);
    let indexof2 = this.datosModulos.CLASES.indexOf(ant);
    let curso_b = btoa("ClaseId=" + this.datosModulos.CLASES[indexof].ID_CLASE)
    let token_ = btoa("Token=" + token)
    //let parametros = btoa("parametros=" + this.PARAMETROS)
    let hash = btoa(curso_b + ";" + token_)
    this.datosModulos.CLASES[indexof].VISTO = 1
    this.datosModulos.CLASES[indexof2].TERMINADO = 1
    this.datosModulos.CLASES[indexof2].VISTO = 0

    this.IFRAME = this.dom.bypassSecurityTrustResourceUrl(URL + "?hash=" + hash);
    this.Titulo = titulo
  }
  Volver() {

    let curso_ = btoa("CursoId=" + this.CursoId)
    //let Modulo_ = btoa("ModuloId=" + id_modulo)
    this.router.navigate(['App/Curso/' + curso_]);
  }
}
export interface DatosModulos {
  ID_MODULO: number
  NOMBRE_MODULO: string
  TOTAL_CLASES: string
  TOTAL_HORA: string
  AVANCE: number
  CLASES: Clase[]
}
export interface Clase {
  ID_CLASE: number
  NOMBRE_CLASE: string
  TOTAL_HORAS: string
  URL_IFRAME: string
  VISTO: number
  TERMINADO: number
  PARAMETROS: string
}