import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ChartComponent,
  ApexNonAxisChartSeries,
  ApexChart,
  ApexPlotOptions,
  ApexFill,
  ApexStroke,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexXAxis
} from "ng-apexcharts";
import { PerfilService } from 'src/app/servicios/perfil/perfil.service';
@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions1: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions2>;
  public TOTAL_TROFEOS: number;
  public NIVEL: _NIVEL = null;
  public CATEGORIA_TOTAL: _CATEGORIA_TOTAL[];
  public P_NIVEL: number;
  public TotalHoras: _TotalHoras[] = null;
  constructor(
    public PerfilService: PerfilService
  ) { }
  Cod_usuario: string
  ngOnInit(): void {

    this.Cod_usuario = window.sessionStorage.getItem("COD_USUARIO")

    this.PerfilService.getTrofeos().subscribe(x => {
      this.TOTAL_TROFEOS = x

      this.PerfilService.getNivel().subscribe(x => {
        this.NIVEL = x[0]

        this.PerfilService.getTotalCursosCategoria().subscribe(x => {
          this.CATEGORIA_TOTAL = x
          this.P_NIVEL = (this.TOTAL_TROFEOS / this.NIVEL.TOTAL_TROFEOS) * 100


          this.PerfilService.getTotaHoras().subscribe(x => {

            this.TotalHoras = x

            let minutos: number[] = []
            let dias: string[] = []

            this.TotalHoras.forEach(x => {
              minutos.push(x.MINUTOS)
              dias.push(x.NOMBRE)
            })


            this.chartOptions2 = {
              series: [
                {
                  name: "Minutos",
                  data: minutos
                }
              ],
              chart: {
                width: 350,
                type: "bar"
              },
              title: {
                text: "Avance Semanal Min"
              },
              xaxis: {
                categories: dias
              }
            };

          })

        })


      })

    });


  }

}

export interface _NIVEL {
  NOMBRE: string;
  IMG: string;
  TOTAL_TROFEOS: number
}
export interface _CATEGORIA_TOTAL {
  CATEGORIA: string;
  TOTAL_CATEGORIA: number;
  TOTAL_USUARIO: number;
}
export interface _TotalHoras {
  DIA: string;
  NOMBRE: string;
  MINUTOS: number;
}

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  plotOptions: ApexPlotOptions;
  labels: string[];
  fill: ApexFill;
  stroke: ApexStroke
};
export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};
