<div class="load" *ngIf="loader===true" >
    <div class="loader"></div>
</div>
<div class="page-content">
    <div class="container home">
        <div class="row mt-5">
            <div class="col-md-12 col-sm-12" >
                <h1>Mis Cursos <span class="text-secondary ml-3">{{this.CURSO.NOMBRE_CURSO}}</span> </h1>            
            </div>       
        </div>
        <div class="row mt-3">
            <div class="col-md-12 col-sm-12 mb-2">
                <div class="card curso shadow-lg" [style.background-color]="this.CURSO.COLOR">
                
                    <div class="card-body ml-5">
                        <h1 class="card-title">{{this.CURSO.NOMBRE_CURSO}}</h1>
                        <img class="curso-icon" src="{{this.CURSO.ICON_CURSO}}" >
                        <h3 class="card-subtitle mb-2 text-white-50">{{this.CURSO.CATEGORIA}}</h3>
                        <p class="card-text text-white-50">{{this.CURSO.DESCRIPCION_CURSO}}</p>
                        <div class="d-flex justify-content-start text-white-50 align-items-end mt-5">
                        <!--    <h5 class="mr-5" style="font-size: 15px;"><i class="far fa-calendar-check fa-2x"></i> Fecha inicio: 01-01-2021 </h5>
                            <h5 style="font-size: 15px;"><i class="far fa-calendar-times fa-2x"></i> Fecha fin: 30-03-2021 </h5>            
                        --></div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-around text-white-50 ml-5 mr-5">
                            <h5 style="font-size: 15px;"><i class="fas fa-book"></i> {{this.CURSO.TOTAL_MODULOS}} Módulos </h5>
                            <h5 style="font-size: 15px;"><i class="far fa-clock"></i> {{this.CURSO.TOTAL_HORAS}} Horas </h5>
                            <h5 style="font-size: 15px;"><i class="fas fa-award"></i> Certificado </h5>
                        </div>
                    </div>
                </div>
            </div>         
        </div>     
        <div class="row mt-3 mb-3">
            <div class="col-12">
                <div class="card curso-bajada shadow-sm">                          
                    <div class="card-body d-flex justify-content-between">                               
                            <button type="button" class="btn btn-success " style="   position: absolute;                           
                            right: 40px;
                            top: 10px;
                            text-align: center;" (click)="downloadPDF()">Descargar a PDF</button>
                            
                    </div>  
                    <div class="card-body" id="pdfDownload1">
                        <h1 class="card-title mx-auto" style="text-align:center;">Métrica del curso</h1>      
                        <div class="row">
                            <div class="col-md-6 mb-2 col-12">
                                <div class="card chart-card">
                                    <div class="card-body d-flex justify-content-between ">
                                        <apx-chart
                                        [series]="chartOptions.series"
                                        [chart]="chartOptions.chart"
                                        [labels]="chartOptions.labels"
                                        [responsive]="chartOptions.responsive"
                                        [title]="chartOptions.title"
                                      ></apx-chart>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-2 col-12">
                                <div class="card chart-card">
                                    <div class="card-body d-flex justify-content-between ">
                                        <apx-chart
                                        [series]="chartOptions2.series"
                                        [chart]="chartOptions2.chart"
                                        [xaxis]="chartOptions2.xaxis"
                                        [title]="chartOptions2.title"
                                      ></apx-chart>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                    </div>    
                        
                
                </div> 
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
        <!-- Recent Order Table -->
        <div class="card curso-bajada shadow-sm">            
            <div class="card-body d-flex justify-content-between">
                <h1 class="card-title mx-auto">Listado de Alumnos</h1>      
                <button type="button" class="btn btn-success " style="   position: absolute;                           
            right: 40px;
            top: 10px;
            text-align: center;" (click)="exportexcel()">Descargar a Excel</button>
        </div>  
        <div class="card-body pt-0 pb-5 f">
        <table class="table card-table table-striped" style="width:100%" id="excel-table">
        <thead>
        <tr>
            <th>Nombre</th>
            <th class="d-none d-lg-table-cell">Fecha última conexión </th>
            <th class="d-none d-lg-table-cell">Avance del curso</th>
            <th>Estado</th>
            <th>Nota Final</th>
            <th>Certificado</th>  
            <th>DJ</th>
            <th>CI</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of this.TABLA_ESTUDIANTES">
            <td>{{item.NOMBRE_ESTUDIANTE}}</td>
            <td>
            <a class="text-dark"> {{item.FECHA_CONEXION}}</a>
            </td>
            <td class="d-none d-lg-table-cell">
                <div class="progress">
                    <div class="progress-bar bg-success" role="progressbar" [style.width]="item.AVANCE/100 | percent" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">{{item.AVANCE}}%</div>
                </div>
            </td>
            <td>
                <span class="badge" [ngClass]="{'badge-warning':item.ESTADO=='Iniciado' , 'badge-danger':item.ESTADO=='No Iniciado' , 'badge-success':item.ESTADO=='Terminado' }" >{{item.ESTADO}}</span>
            </td>
            <td>
                <span class="badge badge-info">{{item.NOTAL_FINAL | percent}}</span>
            </td>
            <td>
                <span class="badge badge-info">{{item.CERTIFICADO ? 'Obtenido' : 'No Obtenido'}}</span>
            </td>
            <td>
                <div class="checkbox checkbox-success">
                    <input id="checkbox" type="checkbox" checked *ngIf="item.DJ==1">
                    <input id="checkbox" type="checkbox" *ngIf="item.DJ!==1">
                  </div>
            </td>
            <td>
                <div class="checkbox checkbox-success">
                    <input id="checkbox" type="checkbox" checked *ngIf="item.CI==1">
                    <input id="checkbox" type="checkbox" *ngIf="item.CI!==1">
                  </div>
            </td>
        </tr>      
        </tbody>
        </table>
        </div>
        </div>
        </div>
        </div>
    </div>
  


</div>





