<div class="page-content">
   
    <div class="container home">
        <img src="../../../assets/img/icon/personaje_2.png" class="personaje2">
        <div class="row mt-5">
        <h1>Bienvenido  , {{datosUsuarios.NOMBRE_ESTUDIANTE}}</h1> 
        </div>  
        <div class="row">
           
            <div class="col-md-6 col-sm-12" >
                <div class="card card-empresa">
                    <div class="card-body shadow-lg">                       
                            
                <div class="row">
                    <div class="col-md-3 col-12 flotante"> <img src="../../../assets/img/icon/braind.png" style="    height: 100px;">    </div>
                    <div class="col-md-9 col-12  mt-3" >
                        <h3>Has invertido {{datosUsuarios.HORAS}} Horas en Capacitación</h3>                      
                    </div>
                </div>
                   
              </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="card card-empresa">
                    <div class="card-body shadow-lg">
                        <div class="row">
                            <div class="col-md-8 col-12">
                                <h3>Trabajador de la empresa </h3>
                                <h2 class="mt-4">{{datosUsuarios.NOMBRE_EMPRESA}} </h2>
                            </div>
                            <div class="col-md-4 col-12 mt-4">
                                <img class="imgEmpresa" src="{{datosUsuarios.LOGO_EMPRESA}}">
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <h2>Tus cursos</h2>
            
            <div class="col-sm-12" >
                <div class="row">
                    <div class="col-sm-12" >
                <ul class="cursos">
                    <li *ngFor="let curso of datosUsuarios.CURSOS">
                        <div class="card card-curso shadow-sm" >                        
                            <img src="{{curso.IMG_CURSO}}" style="border-top-left-radius: 15px;border-top-right-radius: 15px; max-width: 250px; max-width: 250px;">                       
                                <div class="card-body card-body-curse   " >
                                    <h5 class="card-title">{{curso.NOMBRE_CURSO}}</h5>
                                    <p class="card-text">{{curso.DESCRIPCION_CURSO}}</p>                                       
                                    <div class="d-flex justify-content-between">
                                        <h5 style="font-size: 15px;"><i class="fas fa-book"></i> {{curso.TOTAL_MODULOS}} Clases </h5>
                                        <h5 style="font-size: 15px;"><i class="far fa-clock"></i> {{curso.TOTAL_HORAS}} Horas </h5>
                                    </div>
                                    <div class="progress" *ngIf="curso.AVANCE>0">
                                        <div class="progress-bar bg-success" role="progressbar" [style.width]="curso.AVANCE/100 | percent" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">{{curso.AVANCE}}%</div>
                                    </div>
                                </div>
                                
                                <div class="card-footer rounded d-flex justify-content-between" *ngIf="curso.AVANCE==0">                                        
                                    <button type="button"  class="btn btn-success btn-curso rounded-pill" (click)="verCurso(curso.ID_CURSO)">Iniciar</button>
                                </div>
                                <div class="card-footer rounded d-flex justify-content-between" *ngIf="curso.AVANCE>0">                                        
                                    <button type="button"  class="btn btn-success btn-curso rounded-pill" (click)="verCurso(curso.ID_CURSO)">Continuar</button>
                                </div>
                        </div>  
                    </li>  
                                               
                </ul>     
            </div>    
                </div>  
            </div>
            
        </div>
    </div>
</div>  