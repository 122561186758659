import { Component, OnInit, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexTitleSubtitle, ApexXAxis, ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { AdminService } from 'src/app/servicios/admin/admin.service';
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: any;
  title: ApexTitleSubtitle;
};
export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;

};
@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.scss']
})
export class InformesComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions0: Partial<ChartOptions>;
  public chartOptions1: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions2>;
  public loader: boolean = false
  public Numeros: _Numeros;
  public USUARIOS_CURSO: _USUARIOS_CURSO[];
  public CONEXION_SEMANAL: _CONEXION_SEMANAL[];
  public TABLA_ESTUDIANTES: _TABLA_ESTUDIANTES[];
  public LogoEmpresa: string;
  constructor(
    public AdminService: AdminService
  ) { }

  ngOnInit(): void {


    this.AdminService.getDatosAdminGolbal().subscribe(x => {
      this.Numeros = x.Table[0]
      this.USUARIOS_CURSO = x.Table1
      let Total: number[] = []
      let Cursos: string[] = []
      this.USUARIOS_CURSO.forEach(x => {
        Total.push(x.TOTAL)
        Cursos.push(x.NOMBRE_CURSO)
      })

      this.chartOptions0 = {
        series: Total,
        chart: {
          width: 450,
          type: "pie"
        },
        title: {
          text: "Usuarios Por Curso"
        },
        labels: Cursos
      };

      this.CONEXION_SEMANAL = x.Table3
      this.TABLA_ESTUDIANTES = x.Table4
      this.LogoEmpresa = x.Table5[0].LOGO_EMPRESA
      let Total_: number[] = []
      let Dias_: string[] = []
      console.log(this.TABLA_ESTUDIANTES)
      this.CONEXION_SEMANAL.forEach(x => {
        Total_.push(x.TOTAL)
        Dias_.push(x.NOMBRE)
      })


      this.chartOptions2 = {
        series: [
          {
            name: "Conectados",
            data: Total_
          }
        ],
        chart: {
          width: 450,
          type: "bar"
        },
        title: {
          text: "Conexiones Semanales"
        },
        xaxis: {
          categories: Dias_
        }
      };


    })

    this.chartOptions1 = {
      series: [20, 5, 4],
      chart: {
        width: 450,
        type: "pie"
      },
      title: {
        text: "Estatus Usuarios"
      },
      labels: ["Usuarios Sin Terminar", "Usuario terminaron ", "Usuarios sin conexion"]
    };




  }
  exportexcel(): void {
    this.loader = true
    let fileName = 'ExcelSheet.xlsx';
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);
    this.loader = false
  }
  downloadPDF() {
    this.loader = true
    // Extraemos el
    window.scroll(0, 0);

    const DATA = document.getElementById('pdfDownload1');

    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);

      const pdfWidth = doc.internal.pageSize.getWidth() - 2;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
      this.loader = false
    });
  }

}
export interface _Numeros {
  TOTAL_CURSOS: number;
  TOTAL_ESTUDIANTES: number
}
export interface _TotalConexion {
  DIA: string;
  NOMBRE: string;
  TOTAL: number;
}
export interface _USUARIOS_CURSO {
  NOMBRE_CURSO: string;
  TOTAL: number;
}
export interface _CONEXION_SEMANAL {
  DIA: string;
  NOMBRE: string;
  TOTAL: number;
}
export interface _TABLA_ESTUDIANTES {
  ID_ESTUDIANTE: number;
  NOMBRE_ESTUDIANTE: string;
  CURSO: string;
  FECHA_CONEXION: Date;
  AVANCE: number;
  ESTADO: string;
  NOTAL_FINAL: string;
  CERTIFICADO: string;
  DJ: string;
  CI: string;
}