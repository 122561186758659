import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { map } from "rxjs/operators";
import { URL_SERVICIOS } from "src/app/Config/config";

@Injectable({
  providedIn: 'root'
})
export class InicioService {
  private readonly API_URL = URL_SERVICIOS + "api/Inicio";
  constructor(private httpClient: HttpClient) { }

  getDatosUsuarios() {
    let url = this.API_URL + "/DatosUsuarios";
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

}
