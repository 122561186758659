
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { URL_SERVICIOS } from "src/app/Config/config";
import { map } from "rxjs/internal/operators/map";

const TOKEN_KEY = "auth-token";
const COD_USUARIO = "COD_USUARIO";
const ID_PERFIL = "ID_PERFIL";
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private readonly API_URL = URL_SERVICIOS + "api/login";
  constructor(private http: HttpClient) { }
  Usuario: Usuario = null;
  login(user: any): Observable<any> {
    let url = this.API_URL + "/authenticate"
    return this.http.post(url, user).pipe(
      map((resp: any) => {
        //console.log(resp)
        this.Usuario = resp;
        window.sessionStorage.setItem(TOKEN_KEY, this.Usuario.TOKEN);
        window.sessionStorage.setItem(COD_USUARIO, this.Usuario.COD_USUARIO);
        window.sessionStorage.setItem(ID_PERFIL, this.Usuario.ID_PERFIL.toString());
        return true;
      })
    );;
  }
}
export class Usuario {
  ID_PERFIL: number
  COD_USUARIO: string
  TOKEN: string;
}
