<button mat-mini-fab color="primary" aria-label="Example icon button with a filter list icon" (click)="Print()">
    <mat-icon>help_outline</mat-icon>
  </button>
<div id="page_1">
 
    <div id="p1dimg1">
        <img src="../../../../../assets/img/bg1.png" id="p1img1">
    </div>
    
    
    <div class="dclr"></div>
    <div id="id1_1">
    <P class="p0 ft0">APROBADO</P>
    <P class="p1 ft2"><SPAN class="ft1">C</SPAN>ERTIFICADO</P>
    <P class="p2 ft3">SE CONFIERE EL PRESENTE CERTIFICADO A:</P>
    <P class="p3 ft4">&lt;NOMBRE&gt;</P>
    <P class="p4 ft5">Por haber completado y aprobado el Curso:</P>
    <P class="p5 ft6">&lt;CURSO&gt;</P>
    <P class="p6 ft3">Duración Total: &lt;HORAS&gt; horas</P>
    <P class="p7 ft7">&lt;FECHA&gt;</P>
    <table cellpadding=0 cellspacing=0 class="t0">
    <tr>
        <td class="tr0 td0"><P class="p8 ft8">Fecha</P></td>
        <td class="tr0 td1"><P class="p8 ft8">Director</P></td>
    </tr>
    </table>
    </div>
    <div id="id1_2">
    <table cellpadding=0 cellspacing=0 class="t1">
    <tr>
        <td class="tr1 td2"><P class="p9 ft9">+562 32502809</P></td>
        <td class="tr1 td3"><P class="p10 ft9">+569 84568695</P></td>
        <td class="tr1 td4"><P class="p11 ft9">consultas@ditraining.cl</P></td>
        <td class="tr1 td5"><P class="p8 ft10">www.ditraining.cl</P></td>
    </tr>
    </table>
    </div>
    </div>