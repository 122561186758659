<div class="progress-loader" *ngIf="cargando">
  <div class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div class="page-content" *ngIf="!cargando">
  <div class="container home" *ngIf="id !== null">
    <div class="row mt-5">
      <div class="col-md-12 col-sm-12">
        <h1>
          Mis Cursos
          <span class="text-secondary ml-3">{{ datosCurso.NOMBRE_CURSO }}</span>
        </h1>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-7 col-sm-12 mb-2">
        <div
          class="card curso shadow-lg"
          [style.background-color]="datosCurso.COLOR"
        >
          <div class="wrap" *ngIf="eva_modulo_fin > 0.74">
            <span class="ribbon6" style="color: #00000099">Finalizado </span>
          </div>
          <div class="card-body ml-5">
            <h1 class="card-title">{{ datosCurso.NOMBRE_CURSO }}</h1>
            <img class="curso-icon" src="{{ datosCurso.ICON_CURSO }}" />
            <h3 class="card-subtitle mb-2 text-white-50">
              {{ datosCurso.CATEGORIA }}
            </h3>
            <p class="card-text text-white-50">
              {{ datosCurso.DESCRIPCION_CURSO }}
            </p>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-around text-white-50 ml-5 mr-5">
              <h5 style="font-size: 15px">
                <i class="fas fa-book"></i>
                {{ datosCurso.TOTAL_MODULOS }} Módulos
              </h5>
              <h5 style="font-size: 15px">
                <i class="far fa-clock"></i> {{ datosCurso.TOTAL_HORAS }} Horas
              </h5>
              <h5 style="font-size: 15px">
                <i class="fas fa-award"></i> Certificado
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-sm-12" *ngIf="true">
        <div
          class="card curso shadow-sm"
          [style.background-color]="datosCurso.COLOR"
        >
          <span class="ribbon4">Introducción</span>
          <div class="card-body">
            <img class="img-curso-body" src="{{ datosCurso.IMG_CURSO }}" />
          </div>
          <div
            class="card-footer rounded d-flex justify-content-between"
            (click)="VerIntro()"
            style="cursor: pointer"
          >
            <img
              class="btn-ver-introduccion"
              src="../../../assets/img/icon/btnVerVideo.png"
            />
          </div>
        </div>
      </div>
      <div class="col-md-5 col-sm-12" *ngIf="false">
        <div
          class="card curso shadow-sm"
          [style.background-color]="datosCurso.COLOR"
        >
          <span class="ribbon4">Certificado</span>
          <div class="card-body">
            <img class="img-curso-body" src="{{ datosCurso.IMG_CURSO }}" />
          </div>
          <div
            class="card-footer rounded"
            (click)="VerIntro()"
            style="cursor: pointer"
          >
            <button
              type="button"
              class="btn btn-success btn-certificado btn-curso rounded-pill mx-auto"
              (click)="IrEvaluacion(modulos.ID_MODULO)"
            >
              Descarga tu Certificado
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="datosCurso.AVANCE == 0">
      <div class="col-12">
        <div class="card curso-bajada shadow-sm">
          <div class="card-body d-flex justify-content-between">
            <h1 class="card-title mx-auto">¡Tu curso esta por comenzar!</h1>
          </div>
          <div class="card-body d-flex justify-content-between">
            <h2 class="card-title mx-auto">
              Aquí podrás revisar tu avance y ver los módulos que tiene cada
              curso
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="datosCurso.AVANCE > 0">
      <div class="col-12">
        <div class="card curso-bajada shadow-sm">
          <div class="card-body d-flex justify-content-between">
            <h1 class="card-title mx-auto">¡Estos son tus Avances!</h1>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 mb-2">
                <div class="card chart-card">
                  <div class="card-body d-flex justify-content-between">
                    <apx-chart
                      [series]="chartOptions1.series"
                      [chart]="chartOptions1.chart"
                      [plotOptions]="chartOptions1.plotOptions"
                      [labels]="chartOptions1.labels"
                    ></apx-chart>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-2">
                <div class="card chart-card">
                  <div class="card-body d-flex justify-content-between">
                    <apx-chart
                      [series]="chartOptions2.series"
                      [chart]="chartOptions2.chart"
                      [xaxis]="chartOptions2.xaxis"
                      [title]="chartOptions2.title"
                    ></apx-chart>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mb-2">
                <div class="card chart-card">
                  <div
                    class="card-body align-items-center d-flex justify-content-center"
                    style="padding: 0"
                  >
                    <img
                      src="../../../assets/img/icon/braind.png"
                      class="flotante"
                      style="height: 150px; padding: 0px"
                    />
                  </div>
                  <div
                    class="card-body align-items-center justify-content-center"
                    style="padding: 0"
                  >
                    <h1 class="card-subtitle mb-2 text-black-50 text-center">
                      {{ datosCurso.HORAS_CURSO }}
                    </h1>
                    <h2 class="card-subtitle mb-2 text-black-50 text-center">
                      Horas invertidas en Capacitación
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-sm-12">
        <div class="card curso-bajada shadow-sm">
          <div class="card-body d-flex justify-content-between">
            <h1 class="card-title mx-auto">Módulos</h1>
            <button
              mat-mini-fab
              color="primary"
              aria-label="Example icon button with a filter list icon"
              (click)="Ayuda()"
            >
              <mat-icon>help_outline</mat-icon>
            </button>
          </div>
          <div class="row">
            <div class="col-12 mx-auto">
              <div class="row">
                <div class="col-10 offset-1">
                  <div class="main-timeline">
                    <div
                      class="timeline"
                      ngClass="enabled"
                      *ngFor="let modulos of datosCurso.MODULOS"
                    >
                      <div class="timeline-content" ngClass="enabled-bgc">
                        <div class="timeline-icon" ngClass="enabled-bgc">
                          <i class="fas fa-check"></i>
                        </div>
                        <div class="timeline-year" ngClass="enabled-bc">
                          <round-progress
                            style="position: absolute"
                            [current]="modulos.AVANCE"
                            [max]="100"
                            [color]="'#45ccce'"
                            [background]="'#eaeaea'"
                            [radius]="51"
                            [stroke]="10"
                            [semicircle]="false"
                            [rounded]="false"
                            [clockwise]="true"
                            [responsive]="false"
                            [duration]="800"
                            [animation]="'easeInOutQuart'"
                            [animationDelay]="0"
                          >
                          </round-progress>
                          <span
                            style="
                              position: static;
                              color: #bbb;
                              font-weight: 100;
                              line-height: 1;
                              font-size: 20px;
                            "
                            >{{ modulos.AVANCE / 100 | percent }}</span
                          >
                        </div>
                        <div class="inner-content">
                          <h3 class="title" ngClass="enabled-c">
                            {{ modulos.NOMBRE_MODULO }}
                          </h3>

                          <div *ngIf="modulos.EVALUACION !== 0">
                            <h5 class="card-title">Resultado Evaluación</h5>
                            <div class="progress mb-3">
                              <div
                                class="progress-bar bg-primar"
                                [ngClass]="{
                                  'bg-primar': modulos.EVALUACION > 0.7,
                                  'bg-warning':
                                    modulos.EVALUACION > 0.4 &&
                                    modulos.EVALUACION < 0.7,
                                  'bg-danger': modulos.EVALUACION < 0.4
                                }"
                                role="progressbar"
                                [style.width]="modulos.EVALUACION | percent"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {{ modulos.EVALUACION | percent }}
                              </div>
                            </div>
                          </div>

                          <div
                            class="description d-flex justify-content-around"
                          >
                            <div
                              class="rounded"
                              *ngIf="modulos.ESTADO == 1 && modulos.AVANCE == 0"
                            >
                              <button
                                type="button"
                                style="font-size: 12px"
                                class="btn btn-success btn-curso rounded-pill"
                                (click)="IrModulo(modulos.ID_MODULO)"
                              >
                                Iniciar
                              </button>
                            </div>
                            <div
                              class="rounded"
                              *ngIf="modulos.ESTADO == 1 && modulos.AVANCE > 0"
                            >
                              <button
                                type="button"
                                style="font-size: 12px"
                                class="btn btn-success btn-curso rounded-pill"
                                (click)="IrModulo(modulos.ID_MODULO)"
                              >
                                Continuar
                              </button>
                            </div>
                            <div
                              class="rounded"
                              *ngIf="
                                modulos.ESTADO == 1 &&
                                modulos.AVANCE == 100 &&
                                modulos.EVALUACION == 0
                              "
                            >
                              <button
                                type="button"
                                style="font-size: 12px"
                                class="btn btn-success btn-curso rounded-pill"
                                (click)="IrEvaluacion(modulos.ID_MODULO)"
                              >
                                Ir a evaluación
                              </button>
                            </div>
                            <div
                              class="rounded"
                              *ngIf="
                                modulos.ESTADO == 1 &&
                                modulos.AVANCE == 100 &&
                                modulos.EVALUACION > 0
                              "
                            >
                              <button
                                type="button"
                                style="font-size: 12px"
                                class="btn btn-success btn-curso rounded-pill"
                                (click)="IrEvaluacion(modulos.ID_MODULO)"
                              >
                                Repetir evaluación
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="timeline-end" ngClass="enabled">
                      <div
                        class="timeline-end-content"
                        [ngClass]="{ 'enabled-bgc': eva_modulo_fin > 0.74 }"
                      >
                        <div
                          class="timeline-end-year"
                          [ngClass]="{ 'enabled-bc': eva_modulo_fin > 0.74 }"
                        >
                          <round-progress
                            style="position: absolute"
                            [current]="datosCurso.RESULTADO * 100"
                            [max]="100"
                            [color]="'#45ccce'"
                            [background]="'#eaeaea'"
                            [radius]="51"
                            [stroke]="10"
                            [semicircle]="false"
                            [rounded]="false"
                            [clockwise]="true"
                            [responsive]="true"
                            [duration]="800"
                            [animation]="'easeInOutQuart'"
                            [animationDelay]="0"
                          >
                          </round-progress>
                          <span
                            style="
                              position: static;
                              color: #bbb;
                              font-weight: 100;
                              line-height: 1;
                              font-size: 20px;
                            "
                            >{{ datosCurso.RESULTADO | percent }}</span
                          >
                        </div>
                        <div class="inner-content">
                          <h3 class="title-end" ngClass="enabled-c">
                            Evaluación Final
                          </h3>
                          <div
                            class="description d-flex justify-content-around"
                          >
                            <div class="rounded d-flex justify-content-between">
                              <button
                                type="button"
                                style="font-size: 12px"
                                class="btn btn-success btn-curso rounded-pill"
                                (click)="IrEvaluacionFinal()"
                              >
                                Ir a evaluación Final
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card card-curso shadow-lg mb-5 mt-5 mx-auto"
                    [ngClass]="{
                      enabled: this.datosCurso.RESULTADO >= 0.75,
                      disabled: this.datosCurso.RESULTADO < 0.75
                    }"
                    style="z-index: 1"
                  >
                    <div class="wrap">
                      <span class="ribbon6">Certificado</span>
                    </div>
                    <img
                      src="{{ datosCurso.IMG_CURSO }}"
                      style="
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                      "
                    />
                    <div
                      class="card-body"
                      style="
                        font-weight: 500;
                        font-size: 14px;
                        color: #9ba0a7;
                        line-height: 23px;
                      "
                    >
                      <h3 class="card-title">{{ datosCurso.NOMBRE_CURSO }}</h3>
                      <button
                        type="button"
                        *ngIf="true"
                        class="gray btn btn-success btn-curso rounded-pill mx-auto"
                        (click)="
                          this.datosCurso.RESULTADO ? Certificado() : false
                        "
                      >
                        Descarga tu Certificado
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4" *ngIf="Casos !== empty">
      <div class="col-sm-12">
        <div class="card curso-bajada shadow-sm">
          <div class="card-body d-flex justify-content-between">
            <h1 class="card-title mx-auto">Casos pácticos</h1>
          </div>
          <div class="row">
            <div class="col-12 mx-auto">
              <ul class="cursos">
                <li *ngFor="let caso of Casos">
                  <div class="card card-curso shadow-lg">
                    <img
                      src="{{ datosCurso.IMG_CURSO }}"
                      style="
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                      "
                    />
                    <div
                      class="card-body"
                      style="
                        font-weight: 500;
                        font-size: 14px;
                        color: #9ba0a7;
                        line-height: 23px;
                      "
                    >
                      <h5 class="card-title">{{ datosCurso.NOMBRE_CURSO }}</h5>
                      <p class="card-text">{{ caso.NOMBRE }}</p>
                    </div>
                    <div
                      class="card-footer rounded d-flex justify-content-between"
                    >
                      <button
                        type="button"
                        class="btn btn-success btn-curso rounded-pill"
                        (click)="DescargaCaso(caso.URL_DESCARGA)"
                      >
                        Descargar
                      </button>
                    </div>
                    <div
                      class="card-footer rounded d-flex justify-content-between"
                    >
                      <input
                        type="file"
                        name="archivo"
                        (change)="selectFiles($event)"
                        class=""
                        accept=".xlsx"
                      />
                    </div>
                    <div class="card-footer rounded d-flex center">
                      <button
                        type="button"
                        class="btn btn-success btn-curso rounded-pill"
                        (click)="Subir()"
                      >
                        Sube tus Ejercicios
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container home" *ngIf="id == null">
    <div class="row mt-5">
      <h1>Tus cursos</h1>

      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <ul class="cursos">
              <li *ngFor="let curso of datosUsuarios.CURSOS">
                <div class="card card-curso shadow-sm">
                  <img
                    src="{{ curso.IMG_CURSO }}"
                    style="
                      border-top-left-radius: 15px;
                      border-top-right-radius: 15px;
                    "
                  />
                  <div
                    class="card-body"
                    style="
                      font-weight: 500;
                      font-size: 14px;
                      color: #9ba0a7;
                      line-height: 23px;
                    "
                  >
                    <h5 class="card-title">{{ curso.NOMBRE_CURSO }}</h5>
                    <p class="card-text">{{ curso.DESCRIPCION_CURSO }}</p>
                    <div class="d-flex justify-content-between">
                      <h5 style="font-size: 15px">
                        <i class="fas fa-book"></i>
                        {{ curso.TOTAL_MODULOS }} Clases
                      </h5>
                      <h5 style="font-size: 15px">
                        <i class="far fa-clock"></i>
                        {{ curso.TOTAL_HORAS }} Horas
                      </h5>
                    </div>
                    <div class="progress" *ngIf="curso.AVANCE > 0">
                      <div
                        class="progress-bar bg-success"
                        role="progressbar"
                        [style.width]="curso.AVANCE / 100 | percent"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{ curso.AVANCE }}%
                      </div>
                    </div>
                  </div>

                  <div
                    class="card-footer rounded d-flex justify-content-between"
                    *ngIf="curso.AVANCE == 0"
                  >
                    <button
                      type="button"
                      class="btn btn-success btn-curso rounded-pill"
                      (click)="verCurso(curso.ID_CURSO)"
                    >
                      Iniciar
                    </button>
                  </div>
                  <div
                    class="card-footer rounded d-flex justify-content-between"
                    *ngIf="curso.AVANCE > 0"
                  >
                    <button
                      type="button"
                      class="btn btn-success btn-curso rounded-pill"
                      (click)="verCurso(curso.ID_CURSO)"
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </li>
              <img
                src="../../../assets/img/icon/personaje inicio.png"
                class="personaje1"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
