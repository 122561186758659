import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApexAxisChartSeries, ApexTitleSubtitle, ApexXAxis, ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/servicios/admin/admin.service';
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  title: ApexTitleSubtitle;
};
export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};
@Component({
  selector: 'app-curso-detalle',
  templateUrl: './curso-detalle.component.html',
  styleUrls: ['./curso-detalle.component.scss']
})
export class CursoDetalleComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions2>;
  public loader: boolean = false
  public id;
  public CURSO: _CURSO;
  public GRAFICO_TORTA: _GRAFICO_TORTA;
  public CONEXION_SEMANAL: _CONEXION_SEMANAL[];
  public TABLA_ESTUDIANTES: _TABLA_ESTUDIANTES[];
  constructor(private route: ActivatedRoute, public AdminService: AdminService) { }

  ngOnInit(): void {

    this.id = +this.route.snapshot.paramMap.get("id");
    if (this.id !== 0) {

      this.AdminService.getDatosCursoAdmin(this.id).subscribe(x => {

        this.CURSO = x.Table[0]
        this.GRAFICO_TORTA = x.Table1[0]
        this.CONEXION_SEMANAL = x.Table2
        this.TABLA_ESTUDIANTES = x.Table3

        this.chartOptions = {
          series: [this.GRAFICO_TORTA.TOTAL_SIN_TERMINAR, this.GRAFICO_TORTA.TOTAL_TERMINARON, this.GRAFICO_TORTA.TOTAL_SIN_CONEXION],
          chart: {
            width: 450,
            type: "pie"
          },
          title: {
            text: "Estatus Usuarios"
          },
          labels: ["Usuarios Sin Terminar", "Usuario terminaron ", "Usuarios sin conexion"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 450
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        };

        let Total: number[] = []
        let dias: string[] = []

        this.CONEXION_SEMANAL.forEach(x => {
          Total.push(x.TOTAL)
          dias.push(x.NOMBRE)
        })
        this.chartOptions2 = {
          series: [
            {
              name: "conectados",
              data: Total
            }
          ],
          chart: {
            height: 250,
            type: "bar"
          },
          title: {
            text: "conexiones semanales"
          },
          xaxis: {
            categories: dias
          }
        };


      })

    }




  }
  exportexcel(): void {
    this.loader = true
    let fileName = 'ExcelSheet.xlsx';
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);
    this.loader = false
  }
  downloadPDF() {
    this.loader = true
    // Extraemos el
    window.scroll(0, 0);

    const DATA = document.getElementById('pdfDownload1');

    const doc = new jsPDF('p', 'pt', 'a4');
    const options = {
      scale: 3
    };
    html2canvas(DATA, options).then((canvas) => {

      const img = canvas.toDataURL('image/PNG');

      // Add image Canvas to PDF
      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);

      const pdfWidth = doc.internal.pageSize.getWidth() - 2;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((docResult) => {
      docResult.save(`${new Date().toISOString()}_tutorial.pdf`);
      this.loader = false
    });
  }

}
export interface DialogData {
  id: number;
}
export interface _CURSO {
  NOMBRE_CURSO: string;
  CATEGORIA: string;
  DESCRIPCION_CURSO: string;
  ICON_CURSO: string;
  TOTAL_MODULOS: number;
  TOTAL_HORAS: number;
  COLOR: string;
}
export interface _GRAFICO_TORTA {
  TOTAL_SIN_TERMINAR: number;
  TOTAL_TERMINARON: number;
  TOTAL_SIN_CONEXION: number;
}
export interface _TABLA_ESTUDIANTES {
  ID_ESTUDIANTE: number;
  NOMBRE_ESTUDIANTE: string;
  FECHA_CONEXION: Date;
  AVANCE: number;
  ESTADO: string;
  NOTAL_FINAL: string;
  CERTIFICADO: string;
  DJ: string;
  CI: string;
}
export interface _CONEXION_SEMANAL {
  DIA: string;
  NOMBRE: string;
  TOTAL: number;
}