import { Component, OnInit } from '@angular/core';
import { LoginService } from '../servicios/login/login.service';
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder
} from "@angular/forms";
import { Router } from '@angular/router';
const TOKEN_KEY = "auth-token";
const COD_USUARIO = "COD_USUARIO";
const ID_PERFIL = "ID_PERFIL";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isFocused: boolean = false;
  isFocused1: boolean = false;
  Logon: FormGroup;
  Login: Login = { Username: "", Password: "" };
  error: string = null;
  ID_PERFIL: string = ""

  constructor(
    public LoginService: LoginService,
    public router: Router,
    private formBuilder: FormBuilder
  ) {
    this.createLoginForm()
  }
  createLoginForm() {
    this.Logon = this.formBuilder.group({
      Username: [""],
      Password: [""]
    });
  }
  formControl = new FormControl("", [
    Validators.required
    // Validators.email,
  ]);

  ngOnInit(): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(COD_USUARIO);
    window.sessionStorage.removeItem(ID_PERFIL);
  }
  login() {
    this.LoginService.login(this.Login).subscribe(data => {



      this.ID_PERFIL = window.sessionStorage.getItem("ID_PERFIL")
      if (this.ID_PERFIL == "1")
        this.router.navigate(["/App/Inicio"]);
      if (this.ID_PERFIL == "2")
        this.router.navigate(["/App/Admin"]);
    },
      error => {
        console.log(error)
        this.error = error.error;
      });
  }

}

export class Login {
  Username: string
  Password: string
}
