import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ChartComponent,
  ApexNonAxisChartSeries,
  ApexChart,
  ApexPlotOptions,
  ApexFill,
  ApexStroke,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexXAxis
} from "ng-apexcharts";
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CursoDetalleComponent } from './curso-detalle/curso-detalle.component';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/servicios/admin/admin.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions1: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions2>;
  public counto: any;
  public Numeros: _Numeros;
  public TotalConexion: _TotalConexion[];
  public Cursos: _Cursos[];
  public LogoEmpresa: string;
  Cod_usuario: string
  constructor(private router: Router, public AdminService: AdminService) { }


  ngOnInit(): void {

    this.Cod_usuario = window.sessionStorage.getItem("COD_USUARIO");

    this.AdminService.getDatosAdmin().subscribe(x => {
      this.Numeros = x.Table[0]
      this.TotalConexion = x.Table1
      this.Cursos = x.Table2
      this.LogoEmpresa = x.Table3[0].LOGO_EMPRESA
      let Total: number[] = []
      let dias: string[] = []

      this.TotalConexion.forEach(x => {
        Total.push(x.TOTAL)
        dias.push(x.NOMBRE)
      })
      this.chartOptions2 = {
        series: [
          {
            name: "conectados",
            data: Total
          }
        ],
        chart: {
          height: 250,
          type: "bar"
        },
        title: {
          text: "conexiones semanales"
        },
        xaxis: {
          categories: dias
        }
      };


    })

    this.chartOptions1 = {
      series: [25],
      chart: {
        height: 250,
        type: "radialBar",
        toolbar: {
          show: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px"
            },
            value: {
              formatter: function (val) {
                return parseInt(val.toString(), 10).toString();
              },
              color: "#111",
              fontSize: "36px",
              show: true
            }
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: "round"
      },
      labels: ["Usuarios"]
    };

  }
  openDialog(id_curso) {
    this.router.navigate(['App/CursosAdmin/' + id_curso]);
  }
}

export interface _Numeros {
  TOTAL_CURSOS: number;
  TOTAL_ESTUDIANTE: number
}
export interface _TotalConexion {
  DIA: string;
  NOMBRE: string;
  TOTAL: number;
}
export interface _Cursos {
  ID_CURSO: number;
  IMG_CURSO: string;
  NOMBRE_CURSO: string;
  TOTAL_CERTIFICADOS: string;
  TOTAL_ESTUDIANTES: string;
}

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  plotOptions: ApexPlotOptions;
  labels: string[];
  fill: ApexFill;
  stroke: ApexStroke
};
export type ChartOptions2 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};