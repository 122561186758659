import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import * as io from 'socket.io-client';
const SOCKET_ENDPOINT = 'https://chatditraining-paqqz.ondigitalocean.app';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})

export class ChatComponent implements OnInit {
  public data: any;
  public message: string
  public Chat: chatMessage[] = []
  socket: any;
  constructor() {
    this.socket = io(SOCKET_ENDPOINT);
    //this.setupSocketConnection();
  }
  ngOnInit() {
    this.setupSocketConnection();

  }
  setupSocketConnection() {
    this.socket = io(SOCKET_ENDPOINT);

    this.socket.on('message-broadcast', (data: any) => {
      if (data) {
        let nwMsg: chatMessage = {
          class: "left",
          img: data.img,
          message: data.msg,
          name: data.user,
          time: '18-03-2021'
        }
        this.Chat.push(nwMsg);
      }
    });
  }
  SendMessage() {

    let data_: any = {
      msg: this.message,
      user: window.sessionStorage.getItem("COD_USUARIO"),
      img: '../../../assets/img/icon/user-default-img.png'
    }

    this.socket.emit('message', data_);
    let nwMsg: chatMessage = {
      class: "right",
      img: data_.img,
      message: data_.msg,
      name: data_.user,
      time: '19-03-2021'
    }
    this.Chat.push(nwMsg);
    this.message = '';
  }

}


export interface chatMessage {
  class: string;
  img: string;
  message: string;
  name: string
  time: string;
}