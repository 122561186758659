import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { map } from "rxjs/operators";
import { URL_SERVICIOS } from "src/app/Config/config";

@Injectable({
  providedIn: 'root'
})
export class ModuloService {
  private readonly API_URL = URL_SERVICIOS + "api/Modulo";
  constructor(private httpClient: HttpClient) { }

  getDatosModulo(id_modulo) {
    let url = this.API_URL + "/DatosModulo/" + id_modulo;
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

}
