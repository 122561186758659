import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { URL_SERVICIOS } from "src/app/Config/config";
import { Router } from "@angular/router";
import { Observable } from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class CursoService {
  private readonly API_URL = URL_SERVICIOS + "api/Curso";
  constructor(private httpClient: HttpClient, private router: Router) { }

  getDatosCurso(id_curso) {
    let url = this.API_URL + "/DatosCurso/" + id_curso;
    return this.httpClient.get(url).pipe(
      map((resp: any) => {

        return resp;
      }),
      catchError(err => {
        this.router.navigate(["/login"]);
        throw 'error in source. Details: ' + err;
      }),
    );
  }
  getCasosPracticos(id_curso) {
    let url = this.API_URL + "/CasosPracticos/" + id_curso;
    return this.httpClient.get(url).pipe(
      map((resp: any) => {

        return resp;
      }));
  }
  getModuloEvaluacion(id_evaluacion) {
    let url = this.API_URL + "/ModuloEvaluacion/" + id_evaluacion;
    return this.httpClient.get(url).pipe(
      map((resp: any) => {

        return resp;
      }));
  }
  UploadFile(file: File) {

  }
  /*DescargaCertificado(id_curso: number):  Observable<Response> {
    let url = this.API_URL + "/DescargaCertificado/" + id_curso
    return this.httpClient.get(url).pipe(
      map((resp: any) => {
        return resp;
      })
    );;
  }*/

  DescargaCertificado(curso: string) {
    let url = this.API_URL + "/DescargaCertificado/" + curso
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      map((resp: any) => {
        return resp;
      }));
  }
}
