import { Component, OnInit } from '@angular/core';
import { InicioService } from 'src/app/servicios/inicio/inicio.service';
import { Router } from '@angular/router';
import { purecounterjs } from '@srexi/purecounterjs'
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  public datosUsuarios: DatosUsuario = {
    ID_ESTUDIANTE: 0,
    NOMBRE_ESTUDIANTE: "",
    NOMBRE_EMPRESA: "",
    LOGO_EMPRESA: "",
    CURSOS: [],
    HORAS: 0
  }
  constructor(
    public InicioService: InicioService,
    private router: Router
  ) {


  }

  ngOnInit(): void {
    this.InicioService.getDatosUsuarios().subscribe(data => {
      this.datosUsuarios = data
    })
  }
  verCurso(id_curso) {
    let curso_ = btoa("CursoId=" + id_curso)
    //let Modulo_ = btoa("ModuloId=" + id_modulo)
    this.router.navigate(['App/Curso/' + curso_]);
  }

}
export interface DatosUsuario {
  ID_ESTUDIANTE: number;
  NOMBRE_ESTUDIANTE: string;
  NOMBRE_EMPRESA: string;
  LOGO_EMPRESA: string;
  CURSOS: CURSOS[];
  HORAS: number;
}
export interface CURSOS {
  ID_CURSO: number
  NOMBRE_CURSO: string;
  CATEGORIA: string;
  DESCRIPCION_CURSO: string;
  IMG_CURSO: string;
  COLOR: string;
  TOTAL_MODULOS: number;
  TOTAL_HORAS: number;
  AVANCE: number;
}
