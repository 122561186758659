import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  auth_: boolean;

  public canActivate() {
    this.authService.Auth().subscribe(correcto => {
      if (correcto === true) {
        return true;
      } else {
        this.router.navigate(["/login"]);
        return false;
      }
    });
    return true;

  }
}
