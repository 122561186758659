import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { LoaderService } from "../../../servicios/service/loader.service";
@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"]
})
export class LoaderComponent implements OnInit {
  loading: Subject<boolean>;
  constructor(public loaderService: LoaderService) {
    this.loading = loaderService.isLoading
  }

  ngOnInit(): void { }
}
