<div class="page-content">
    <div class="container module">
        <div class="row">
            <div class="col-md-9 col-sm-12 list-clases" style="padding: inherit; height: 100%;">
                               <div class="card clase-bajada" style="padding: inherit;">     
                    <div class="card-header titulo">
                        <div class="row">
                            <h2 class="btn btn-outline-light btn-lg  ml-5 mt-1 " style="text-align: center" >{{this.datosModulos.NOMBRE_MODULO}}</h2>
                            <h1 class="btn btn-success btn-lg ml-5 mt-1" style="text-align: center" >{{Titulo}}</h1>                                     
                        </div>
                                       
                    </div>
                    <div class="card-body">
                        <div class="video-container">
                            <iframe [src]="IFRAME" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            
                        </div>  
                    </div>
                    <div class="card-footer clase-bajada d-flex justify-content-around">
                        <button type="button " class="btn btn-success btn-lg border-0" (click)="Volver()"><i class="far fa-arrow-alt-circle-left"></i> <span>  Volver al Curso</span></button>                              
                    </div>                
                </div> 
                  
            </div>       
            <div class="col-md-3 col-sm-12 list-clases">
                <mat-tab-group dynamicHeight mat-align-tabs="center">
                    <mat-tab>
                        <ng-template mat-tab-label style="width: 50px;">
                            <mat-icon class="text-light" style="font-size: 30px;">rate_review</mat-icon>
                          </ng-template>                          
                    
                         <div class="list list-clases-scroll">
                         <div class="timeline " *ngFor="let clase of datosModulos.CLASES ; let i = index" >
                            <div class="timeline-container" (click)="clase.TERMINADO == 1 ? iframe(clase.URL_IFRAME , clase.NOMBRE_CLASE) : false" [ngClass]="{'primary': clase.TERMINADO==1 || clase.VISTO==1 || clase.TERMINADO==0 && i==0 , 'disable': clase.TERMINADO==0 && clase.VISTO==0 && i>0 }">
                                <div class="timeline-icon " [ngClass]="{'click':clase.VISTO==1 || clase.TERMINADO==1 }" (click)="clase.VISTO==1 || clase.TERMINADO==1 ? iframe(clase.URL_IFRAME ,clase.NOMBRE_CLASE) : false">                                  
                                    <i *ngIf="clase.TERMINADO == 1 " class="fas fa-check"></i>
                                    <i *ngIf="clase.TERMINADO == 0 && clase.VISTO == 0 && i > 0" class="fas fa-lock"></i> 
                                    <i *ngIf="clase.TERMINADO == 0 && i == 0" class="fas fa-unlock-alt"></i> 
                                    <i *ngIf="clase.TERMINADO == 0 && clase.VISTO == 1" class="fas fa-unlock-alt"></i> 
                                </div>
                                <div class="timeline-body primary">
                                    <h4 class="timeline-title"><span class="badge_">{{clase.NOMBRE_CLASE}}</span></h4>                              
                                </div>
                            </div>                          
                        </div>
                    </div>

                          </mat-tab>
                        
                          <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon class="text-light" style="font-size: 30px; width: 50px;">chat</mat-icon>
                            </ng-template>
                            <div class="row ">
                                  <app-chat style="width: 100%;" class="ml-4 mr-5 mt-1"></app-chat>
                            </div>
                          </mat-tab>
                        
                </mat-tab-group>
                
               
                 
            </div> 
    </div>
    <div class="row">
      
    </div>
    </div>
</div>
<div id='whatsapp-chat' class="{{chatwsp}}">
	<div class='header-chat'>
	<div class='head-home'><h3>Ditraining</h3>
		<div class='info-avatar'><img src='../../../assets/img/icon/supportmale.png'/></div>
		
	</div>
 
	<div class='get-new'>
		<div id='get-label'>Soporte</div>
		<div id='get-nama'>Servicio al cliente</div>
		
	</div>
</div>
 
<div class='start-chat'>
<div class='first-msg'><span>¡Hola! ¿Qué puedo hacer por ti?</span></div>
<div class='blanter-msg'><textarea id='chat-input' [(ngModel)]="msg" placeholder='Escribe un mensaje' maxlength='120' row='1'></textarea>
<a (click)="enviar_mensaje()" id='send-it'>Enviar</a></div></div>
<div id='get-number'></div><a class='close-chat' (click)="cerrar_chat();">×</a>
</div>
 
 
<a class='blantershow-chat' (click)="mostrar_chat()" title='Show Chat'><svg width="20" viewBox="0 0 24 24"><defs/><path fill="#eceff1" d="M20.5 3.4A12.1 12.1 0 0012 0 12 12 0 001.7 17.8L0 24l6.3-1.7c2.8 1.5 5 1.4 5.8 1.5a12 12 0 008.4-20.3z"/><path fill="#4caf50" d="M12 21.8c-3.1 0-5.2-1.6-5.4-1.6l-3.7 1 1-3.7-.3-.4A9.9 9.9 0 012.1 12a10 10 0 0117-7 9.9 9.9 0 01-7 16.9z"/><path fill="#fafafa" d="M17.5 14.3c-.3 0-1.8-.8-2-.9-.7-.2-.5 0-1.7 1.3-.1.2-.3.2-.6.1s-1.3-.5-2.4-1.5a9 9 0 01-1.7-2c-.3-.6.4-.6 1-1.7l-.1-.5-1-2.2c-.2-.6-.4-.5-.6-.5-.6 0-1 0-1.4.3-1.6 1.8-1.2 3.6.2 5.6 2.7 3.5 4.2 4.2 6.8 5 .7.3 1.4.3 1.9.2.6 0 1.7-.7 2-1.4.3-.7.3-1.3.2-1.4-.1-.2-.3-.3-.6-.4z"/></svg> 
Reportar un problema</a>