<div class="page-content">
    <div class="container home">
        <div class="row mt-5">
            <div class="col-md-12 col-sm-12 " >
                <h1>Perfil</h1>            
            </div>       
        </div>
        <div class="row mt-3">
            <div class="col-md-12 col-sm-12 mb-2">
                <div class="card perfil shadow-lg">                
                    <div class="card-body ml-5" *ngIf="NIVEL!==null">
                        <div class="media py-3 align-items-center justify-content-between">
                            <div class="d-flex rounded-circle align-items-center justify-content-center mr-3 media-icon iconbox-45 text-white" style=" pointer-events: auto;
                            background: grey;
                            width: 180px;
                            height: 180px;">
                               <img class="rounded-circle"  src="../../../assets/img/icon/user-default-img.png" width="162" height="162">
                            </div>
                            <div class="media-body pr-3">
                              <h1 class="mt-0 mb-1 font-size-15" style="color: #4caf50;">{{Cod_usuario}}</h1>                             
                              <div class="progress_bar">
                                <div class="pro-bar">
                                    <h3 class="progress_bar_title">
                                       {{this.NIVEL.NOMBRE}}                                       
                                    </h3>
                                    <span class="progress-bar-inner" style="background-color: #fdba04;" [style.width.%] ="P_NIVEL">
                                        <span class="progress_number">{{P_NIVEL}}</span></span>
                                        <small class="progress_bar_subtitel">Completa los cursos para obtener trofeos y subir de nivel</small>
                                </div>
                            </div>
                            
                            </div>
                            <span class="d-inline-block"><img src="{{this.NIVEL.IMG}}" style="height: 100px;"></span>
                          </div>    
                                          
                    </div>
                   
                </div>
                
            </div>
            <div class="col-md-12 col-sm-12 mt-3 mb-2">
                <div class="card perfil shadow-lg">                
                    <div class="card-body ml-5">
                        <div class="media py-3 align-items-center justify-content-around">
                            <div class="d-flex rounded-circle align-items-center justify-content-center mr-3 media-icon iconbox-45 text-white" style=" pointer-events: auto;
                           ">
                               <img class="rounded-circle"  src="../../../assets/img/icon/cir-3.png" width="162" height="162" data-index="0" alt="Carlos Alzerreca - Desarrollador full stack - FASTCO | LinkedIn" data-iml="1219.2749999958323" data-atf="true">
                            </div>
                            <div class="media-body pr-3">
                                <div class="row">
                                    <div class="col-6">
                                        <h1 class="mt-0 mb-1 font-size-15" style="color: #9e56cc;">Cursos Finalizados</h1>
                                        <div class="media py-3 d-flex justify-content-start">
                                            <div class="mx-center mr-2" *ngFor="let item of CATEGORIA_TOTAL">
                                                <h3 class="text-muted" style="margin-bottom: 2px; text-align: center;     font-size: 20px;">{{item.CATEGORIA}}</h3>
                                                <span class="badge badge-pill badge-estatus"><h2>{{item.TOTAL_USUARIO}}/{{item.TOTAL_CATEGORIA}}</h2></span>
                                            </div>                                                                       
                                        </div>
                                    </div>    
                                    <div class="col-6" *ngIf="TotalHoras!==null">
                                        <apx-chart
                                        [series]="chartOptions2.series"
                                        [chart]="chartOptions2.chart"
                                        [xaxis]="chartOptions2.xaxis"
                                        [title]="chartOptions2.title"
                                    ></apx-chart>
                                    </div> 
                            </div>                       
                            </div>

                          </div>        
                    </div>                   
                </div>                
            </div>
            <div class="col-md-12 col-sm-12 mt-3">
                <div class="card perfil shadow-lg">                
                    <div class="card-body ml-5">
                        <div class="media py-3 align-items-center justify-content-around">
                            <div class="d-flex rounded-circle align-items-center justify-content-center mr-3 media-icon iconbox-45 text-white" style=" pointer-events: auto;
                           ">
                               <img class="rounded-circle"  src="../../../assets/img/icon/cir-2.png" width="162" height="162" data-index="0" alt="Carlos Alzerreca - Desarrollador full stack - FASTCO | LinkedIn" data-iml="1219.2749999958323" data-atf="true">
                            </div>
                            <div class="media-body pr-3">
                              <h1 class="mt-0 mb-1 font-size-15" style="color: #e8b536;">Total Trofeos</h1>
                              <h3 class="text-black-50">Ranking 1</h3>
                              <div class="media py-3">
                                  <div class="mx-center">                                    
                                      <span class="badge badge-pill badge-estatus-trofeos"><h2>{{this.TOTAL_TROFEOS}}</h2></span>
                                  </div>
                              </div>
                            
                            </div>
                      
                          </div>        
                    </div>                   
                </div>                
            </div>
        </div>
    </div>
</div>